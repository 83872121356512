<template>
  <div>
    <q-dialog v-model="dialog.show">
      <project-techs v-if="dialog.which === 'techs'" :title="dialog.title" :items="dialog.items" />
      <project-medias v-if="dialog.which === 'medias'" :title="dialog.title" :items="dialog.items" />
    </q-dialog>
    <q-card v-if="profile">
      <q-table
        class="sticky-action full-width"
        title="Projects"
        :rows="rows"
        :columns="columns"
        :filter="filter"
        row-key="id"
      >
        <template #top-left>
          <span class="text-h6 q-ml-sm">Projects</span>
        </template>
        <template #top-right>
          <q-input dense debounce="300" color="primary" v-model="filter">
            <template v-slot:append>
              <q-icon name="search" />
            </template>
          </q-input>
        </template>
        <template #body-cell-repository="{ row }">
          <td>
            <a :href="row.repository" target="_blank">{{ row.repository }}</a>
          </td>
        </template>
        <template #body-cell-url="{ row }">
          <td>
            <a :href="row.url" target="_blank">{{ row.url }}</a>
          </td>
        </template>
        <template #body-cell-action="props">
          <td>
            <q-btn-group>
              <q-btn color="secondary" size="xs" round :icon="props.expand && which === 'tech' ? 'star_outline' : 'star'" :to="`projects#${props.row.id}/techs`" @click="(dialog.id === props.row.id && dialog.which === 'techs') ? dialog.show = true : null">
                <q-tooltip>
                  Technology
                </q-tooltip>
              </q-btn>
              <q-btn color="accent" size="xs" round :icon="props.expand && which === 'media' ? 'link_off' : 'link'" :to="`projects#${props.row.id}/medias`" @click="(dialog.id === props.row.id && dialog.which === 'medias') ? dialog.show = true : null">
                <q-tooltip>
                  Media
                </q-tooltip>
              </q-btn>
            </q-btn-group>
          </td>
        </template>
      </q-table>
    </q-card>
  </div>
</template>

<script>
import { computed, ref, watchEffect } from '@vue/runtime-core'
import { capitalize, limitText } from '../utils/functions'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import ProjectTechs from './ProjectTechs.vue'
import ProjectMedias from './ProjectMedias.vue'

export default {
  components: { ProjectTechs, ProjectMedias },
  setup () {
    const route = useRoute()
    const store = useStore()
    const profile = computed(() => store.state.profile)

    const dialog = ref({
      show: false,
      which: 'techs',
      id: '',
      title: '',
      items: null
    })

    const filter = ref('')

    const columns = ref([
      { name: 'action', label: '', align: 'left' },
      { name: 'title', label: 'Title', align: 'left', field: 'title', sortable: true },
      { name: 'description', label: 'Description', align: 'left', field: 'description', sortable: true, format: val => limitText(val, 100, true) },
      { name: 'repository', label: 'Repository', align: 'left', field: 'repository', sortable: true, format: val => limitText(val, 100, true) },
      { name: 'url', label: 'Url', align: 'left', field: 'url', sortable: true, format: val => limitText(val, 100, true) }
    ])

    const rows = computed(() => store.state.projects)

    watchEffect(() => {
      if (route.hash) {
        const split = route.hash.split('/')
        const id = split[0].slice(1)
        const which = split[1]
        const find = rows.value.find(e => e.id === id)

        if (find) {
          dialog.value.show = true
          dialog.value.which = which
          dialog.value.id = id
          dialog.value.title = `${capitalize(which)} of ${find?.title}`
          dialog.value.items = find[which]
        }
      }
    })

    return {
      profile,
      filter,
      columns,
      rows,
      dialog
    }
  }
}
</script>
