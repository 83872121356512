<template>
  <div>
    <!-- Dialog Preview -->
    <q-dialog v-model="dialogPreview.show">
      <q-card style="width: 1280px; max-width: 90vw;">
        <q-card-section class="row items-center">
          <q-img
            v-if="dialogPreview.type === 'image'"
            :src="dialogPreview.source"
            :ratio="16/9"
            spinner-color="primary"
            spinner-size="82px"
            fit="contain"
          />
          <video
            v-if="dialogPreview.type === 'video'"
            :src="dialogPreview.source"
            style="width:100%"
            controls
          />
          <audio
            v-if="dialogPreview.type === 'audio'"
            :src="dialogPreview.source"
            style="width:100%"
            controls
          />
        </q-card-section>
      </q-card>
    </q-dialog>
    <q-card>
      <q-table
        class="sticky-action full-width"
        :title="title"
        :rows="items"
        :columns="columns"
        :filter="filter"
        row-key="id"
      >
        <template #top-left>
          <span class="text-h6 q-ml-sm">{{ title }}</span>
        </template>
        <template #top-right>
          <q-input dense debounce="300" color="primary" v-model="filter">
            <template v-slot:append>
              <q-icon name="search" />
            </template>
          </q-input>
        </template>
        <template #body-cell-url="{ row }">
          <td class="cursor-pointer" @click="onSelectMedia(row)">
            {{ row.url }}
          </td>
        </template>
      </q-table>
    </q-card>
  </div>
</template>

<script>
import { computed, ref } from '@vue/runtime-core'
import { capitalize, limitText } from '../utils/functions'
import { useStore } from 'vuex'

export default {
  props: {
    title: {
      type: String,
      default: ''
    },
    items: {
      type: Array,
      default: () => ([])
    }
  },
  setup (props) {
    const store = useStore()
    const profile = computed(() => store.state.profile)

    const dialogPreview = ref({
      show: false,
      type: 'image',
      source: ''
    })

    const filter = ref('')

    const columns = ref([
      { name: 'title', label: 'Title', align: 'left', field: 'title', sortable: true },
      { name: 'description', label: 'Description', align: 'left', field: 'description', sortable: true, format: val => limitText(val, 100, true) },
      { name: 'type', label: 'Type', align: 'left', field: 'type', sortable: true, format: val => capitalize(val, 100, true) },
      { name: 'url', label: 'Url', align: 'left', field: 'url', sortable: true, format: val => limitText(val, 100, true) }
    ])

    const rows = computed(() => props.items)

    const onSelectMedia = async (selected) => {
      dialogPreview.value.show = true
      dialogPreview.value.type = selected?.type
      dialogPreview.value.source = selected?.url
      console.log(dialogPreview.value)
    }

    return {
      profile,
      filter,
      columns,
      rows,
      dialogPreview,
      onSelectMedia
    }
  }
}
</script>
